import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface ILeaderProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const LeaderImage: React.FunctionComponent<ILeaderProps> = ({
  section,
}) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;
  return (
    <LeaderContainer
      container={true}
      justify='center'
      style={{ backgroundImage: `url(${frontmatter.leaderImage})` }}
    >
      <MaxWidthContainer style={{ position: 'relative', width: '100%' }}>
        <ButtonContainer>
          {frontmatter?.buttons?.map((button, index) => {
            return (
              <CTA
                key={`cta-button-${index}`}
                // component={Link}
                href={button?.link || '#'}
                variant='contained'
                style={{
                  backgroundColor: index % 2 === 0 ? '#C1B2AD' : '#B2B4BB',
                }}
              >
                {button?.buttonText}
              </CTA>
            );
          })}
        </ButtonContainer>
      </MaxWidthContainer>
    </LeaderContainer>
  );
};

const LeaderContainer = withStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: `${theme.spacing(5)}px`,
    backgroundSize: 'cover',
    [theme.breakpoints.up('xs')]: {
      minHeight: '550px',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '200px',
    },
  },
}))(Grid);

const ButtonContainer = withStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(-2),
    right: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))(Grid);

const CTA = withStyles(theme => ({
  root: {
    width: '190px',
    height: '114px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '28px',
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
    textTransform: 'none',
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      height: '55px',
      width: '146px',
    },
  },
}))(Button);
