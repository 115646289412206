import {
  Button,
  Grid,
  Link,
  makeStyles,
  SvgIcon,
  withStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import phoneIcon from '../../images/phoneIcon.svg';
import LogoMobile from '../../images/studiostrack-logo-mobile.svg';
import Logo from '../../images/studiostrack-logo.svg';

// import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

const useMenuStyles = makeStyles(theme => ({
  menuItems: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      order: 2,
      display: 'none',
    },
  },
  menuItemsFoldedout: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      order: 2,
      display: 'flex',
    },
  },
  menuItem: {
    paddingRight: theme.spacing(2),
  },
  menuHamburgerIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const useStyles = makeStyles(theme => ({
  menuHamburgerIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
interface INavigationProps {
  //   section: MarkdownRemarkFrontmatterSections;
}

export const Navigation: React.FunctionComponent<INavigationProps> = () =>
  // {
  // section,
  // },
  {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const classes = useStyles({ menuOpen });

    //   if (!section || !section.componentId || !section.componentId.frontmatter) {
    //     return <p>content invalid</p>;
    //   }
    //   const { frontmatter } = section.componentId;
    return (
      <NavigationBar container={true} justify='center'>
        <MaxWidthContainer container={true} wrap='wrap' item={true} xs={12}>
          <Grid
            item={true}
            container={true}
            xs={6}
            sm={4}
            alignItems='center'
            style={{ padding: '10px 10px 0 10px' }}
          >
            <Link href='/'>
              <img
                src={Logo}
                srcSet={`${LogoMobile} 200w, ${Logo} 300w`}
                sizes='(max-width: 600px) 200px,
                300px'
                alt='studio Strack logo'
              />
            </Link>
          </Grid>
          <MenuItems menuOpen={menuOpen} />
          <Grid
            alignItems='center'
            container={true}
            xs={6}
            sm={1}
            item={true}
            justify='flex-end'
          >
            <Grid xs={3} sm={12} item={true}>
              <RoundButton href='tel:+316 42 74 19 62'>
                <img src={phoneIcon} alt='Bel ons!' />
              </RoundButton>
            </Grid>
            <Grid
              alignItems='center'
              container={true}
              xs={3}
              // sm={1}
              item={true}
              justify='flex-end'
              style={{
                padding: '10px',
              }}
              className={classes.menuHamburgerIcon}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <SvgIcon component={MenuIcon} />
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </NavigationBar>
    );
  };

const NavigationBar = withStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    zIndex: 99,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },
  },
}))(Grid);

const MenuItems: React.FunctionComponent<{ menuOpen: boolean }> = React.memo(
  ({ menuOpen }) => {
    const classes = useMenuStyles();

    return (
      <Grid
        item={true}
        container={true}
        justify='flex-end'
        sm={7}
        xs={12}
        className={menuOpen ? classes.menuItemsFoldedout : classes.menuItems}
      >
        <Grid
          alignItems='center'
          container={true}
          item={true}
          xs={12}
          sm={2}
          justify='flex-end'
          className={classes.menuItem}
        >
          <Button variant='text' href='/aanbod'>
            Aanbod
          </Button>
        </Grid>
        <Grid
          alignItems='center'
          container={true}
          item={true}
          xs={12}
          sm={2}
          justify='flex-end'
          className={classes.menuItem}
        >
          <Button variant='text' href='/verkopen'>
            Verkopen
          </Button>
        </Grid>
        <Grid
          alignItems='center'
          container={true}
          item={true}
          xs={12}
          sm={2}
          justify='flex-end'
          className={classes.menuItem}
        >
          <Button variant='text' href='/team'>
            Team
          </Button>
        </Grid>
        <Grid
          alignItems='center'
          container={true}
          item={true}
          xs={12}
          sm={2}
          justify='flex-end'
          className={classes.menuItem}
        >
          <Button variant='text' href='/contact'>
            Contact
          </Button>
        </Grid>
      </Grid>
    );
  },
);
