import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import roomsIcon from '../../images/rooms.svg';
import sqmIcon from '../../images/sqm.svg';

interface IPropertySpecs {
  street: string;
  houseNumber: string;
  city: string;
  price: string | number;
  sqm: string | number;
  rooms: string | number;
  style?: React.CSSProperties;
  large?: boolean;
}
export const PropertySpecs: React.FunctionComponent<IPropertySpecs> = ({
  street,
  houseNumber,
  city,
  price,
  sqm,
  rooms,
  style,
  large,
}) => (
  <>
    <Grid xs={12} item={true} style={style}>
      <Typography
        variant={large ? 'h2' : 'body1'}
        style={{ fontWeight: 'bold' }}
      >{`${street} ${houseNumber}`}</Typography>
      <Typography
        variant={large ? 'h3' : 'body1'}
        style={large && { marginBottom: '20px' }}
      >
        {city}
      </Typography>
      <Typography
        variant={large ? 'h4' : 'body1'}
        style={{ fontWeight: 'bold' }}
      >{`€${price},-`}</Typography>
    </Grid>
    <Grid container={true} xs={12} item={true}>
      <Grid item={true} container={true} alignItems='center' xs={4}>
        <img src={sqmIcon} alt='M2' style={{ marginRight: '8px' }} />
        {sqm}
      </Grid>
      <Grid item={true} container={true} alignItems='center' xs={4}>
        <img
          src={roomsIcon}
          alt='aantal kamers'
          style={{ marginRight: '8px' }}
        />
        {rooms} kamers
      </Grid>
    </Grid>
  </>
);
