import { createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  palette: {
    background: {
      default: '#F3F0EF',
    },
    primary: {
      main: '#C1B2AD',
      // main: '#18416D',
      // main: '#9BDED9',
    },
    secondary: {
      main: '#B2B4BB',
    },
  },
  typography: {
    fontFamily: 'Nunito Sans',
    h2: {
      color: '#000000',
      fontSize: '40px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h3: {
      fontSize: '25px',
      fontWeight: 'bold',
      color: '#000000',
      letterSpacing: 0,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#000000',
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: '40px',
      color: '#18416D',
      fontWeight: 'normal',
    },
    body1: {
      color: '#000000',
      lineHeight: '28px',
    },
    body2: {
      color: '#000000',
    },
  },
  spacing: 8,
});

muiTheme.typography.h1 = {
  ...muiTheme.typography.h1,
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '60px',
  lineHeight: '70px',
  /* or 117% */

  letterSpacing: '2px',
  color: '#18416D',
  [muiTheme.breakpoints.down('md')]: {
    fontSize: '34px',
    lineHeight: '34px',
  },
  [muiTheme.breakpoints.down('xs')]: {
    fontSize: '24px',
    lineHeight: '24px',
  },
};

muiTheme.typography.h2 = {
  ...muiTheme.typography.h2,
  [muiTheme.breakpoints.down('xs')]: {
    fontSize: '24px',
    lineHeight: '24px',
  },
};

muiTheme.typography.h3 = {
  ...muiTheme.typography.h3,
  [muiTheme.breakpoints.down('xs')]: {
    fontSize: '20px',
    lineHeight: '20px',
  },
};

muiTheme.typography.subtitle1 = {
  ...muiTheme.typography.subtitle1,
  [muiTheme.breakpoints.down('md')]: {
    fontSize: '34px',
    lineHeight: '34px',
  },
  [muiTheme.breakpoints.down('xs')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
};

muiTheme.overrides = {
  MuiInputBase: {
    root: {
      width: '100%',
      backgroundColor: 'white',
      '& input': {
        [muiTheme.breakpoints.down('xs')]: { padding: '10.5px 14px' },
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      [muiTheme.breakpoints.down('xs')]: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
  },
  MuiLink: {
    root: {
      fontWeight: 'bold',
      color: 'black',
    },
  },
  MuiButton: {
    text: {
      letterSpacing: '0px',
      textTransform: 'none',
    },
    contained: {
      [muiTheme.breakpoints.up('xs')]: {
        paddingTop: '14px',
        paddingBottom: '14px',
      },
      [muiTheme.breakpoints.down('xs')]: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
  },
  MuiSelect: {
    outlined: {
      [muiTheme.breakpoints.down('xs')]: { padding: '10.5px 14px' },
    },
  },
};

export const theme = muiTheme;
