import { Grid, SvgIcon, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/styles';
import { navigate } from 'gatsby';
import React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '../../components/ControlledInput/ControlledInput';
import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import FrameImage2 from '../../images/frame2.svg';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IContactProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const Contact: React.FunctionComponent<IContactProps> = ({
  section,
}) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
    mode: 'onTouched',
    shouldUnregister: false,
  });

  const onSubmit = async (data: any) => {
    console.log(data);
    const response = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        'form-name': 'contactForm',
        ...data,
      }).toString(),
    });

    if (response.status === 200) {
      navigate('/bedankt');
      return;
    }

    const message = response.json();

    window.alert(JSON.stringify(message));
  };

  return (
    <GreyContainer container={true} justify='center'>
      <MaxWidthWithPadding container={true} justify='center'>
        <TextContainer
          item={true}
          xs={10}
          sm={6}
          container={true}
          style={{ paddingBottom: '25px' }}
        >
          <Typography variant='h2'>{frontmatter.title}</Typography>
          <Typography style={{ margin: '30px 0' }}>
            {frontmatter.text}
          </Typography>
          <form name='contactForm' method='POST' data-netlify='true'>
            <Grid container={true} spacing={1} item={true}>
              <Grid item={true} xs={12}>
                <ControlledInput
                  name='name'
                  label='naam'
                  control={control}
                  type='text'
                  placeholder='Naam'
                  rules={{ required: 'Naam is vereist' }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <ControlledInput
                  name='phone'
                  label='Telefoonnummer'
                  control={control}
                  type='text'
                  placeholder='Telefoonnummer'
                />
              </Grid>
              <Grid item={true} xs={12}>
                <ControlledInput
                  name='email'
                  label='Emailadres*'
                  control={control}
                  type='text'
                  placeholder='Emailadres*'
                  rules={{
                    required: 'Email is vereist',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ongeldig emailadres',
                    },
                  }}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <ControlledInput
                  name='message'
                  label='Bericht*'
                  control={control}
                  placeholder='Bericht*'
                  multiline={true}
                  rows={4}
                  rules={{ required: 'Bericht is vereist' }}
                />
              </Grid>
              <Grid
                item={true}
                xs={8}
                style={{
                  padding: '25px 0 0 0',
                }}
              >
                <Grid container={true} alignItems='center' spacing={1}>
                  <Grid item={true} onClick={handleSubmit(onSubmit)}>
                    <Typography>verzenden</Typography>
                  </Grid>
                  <Grid item={true}>
                    <RoundButton
                      color='default'
                      onClick={handleSubmit(onSubmit)}
                    >
                      <SvgIcon component={ArrowForwardIosIcon} />
                    </RoundButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </TextContainer>
        <Grid
          item={true}
          xs={10}
          sm={6}
          container={true}
          alignItems='flex-start'
        >
          <ImageContainer
            item={true}
            xs={12}
            container={true}
            justify='flex-start'
            alignItems='flex-start'
          >
            <img
              src={frontmatter.image}
              alt={frontmatter.title || 'contact us'}
            />
          </ImageContainer>
        </Grid>
      </MaxWidthWithPadding>
    </GreyContainer>
  );
};

const MaxWidthWithPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(5)}px ${theme.spacing(0)}`,
  },
}))(MaxWidthContainer);

const GreyContainer = withStyles(theme => ({
  root: {
    width: '100%',
    background: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.main}) no-repeat right 30vw top 0`,
    [theme.breakpoints.down('sm')]: {
      background: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.secondary.main}) no-repeat right 0 top 0`,
    },
    padding: `${theme.spacing(5)}px ${theme.spacing(0)}`,
  },
}))(Grid);

const ImageContainer = withStyles({
  root: {
    backgroundImage: `url(${FrameImage2})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: '90% 90%',
    padding: '0 40px 50px 0',
    '& img': {
      width: '100%',
    },
  },
})(Grid);

const TextContainer = withStyles(theme => ({
  root: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },
}))(Grid);
