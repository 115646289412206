import {
  Grid,
  ThemeProvider as MaterialThemeProvider,
  withStyles,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

// import GlobalStyles from './globalStyles';
import { theme } from './globalStyles/styles';

export const Layout: FC = ({ children }) => (
  <>
    <Helmet>
      <meta name='viewport' content='width=device-width' />
      <link rel='preconnect' href='https://fonts.gstatic.com' />
      <link
        rel='preload'
        href='https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'
        as='style'
        // @ts-ignore
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      <link
        rel='preload'
        href='https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap'
        as='style'
        // @ts-ignore
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      <noscript>
        {`<link
          href='https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'
          rel='stylesheet'
          type='text/css'
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap'
          rel='stylesheet'
          type='text/css'
        />
        `}
      </noscript>
    </Helmet>
    <MaterialThemeProvider theme={theme}>
      <CssBaseline />
      <MainContainer
        justify='center'
        alignItems='center'
        direction='column'
        container={true}
      >
        {children}
      </MainContainer>
    </MaterialThemeProvider>
  </>
);

const MainContainer = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
    },
  },
}))(Grid);
