import { Grid, Link, SvgIcon, Typography } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IInfoBlockProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const Footer: React.FunctionComponent<IInfoBlockProps> = ({
  section,
}) => {
  if (!section) {
    return <p>content invalid</p>;
  }
  const frontmatter = section;
  return (
    <>
      <FullWidthContainer container={true} justify='center'>
        <MaxWidthContainerPadding container={true}>
          <Grid container={true} justify='center'>
            <FooterSection
              item={true}
              xs={10}
              sm={3}
              container={true}
              spacing={2}
            >
              <Grid item={true} xs={12}>
                <Typography variant='h4'>Contact</Typography>
                <Typography>{frontmatter.name}</Typography>
                <Typography>{frontmatter.phone}</Typography>
                <Typography>
                  <Link href={`mailto:${frontmatter.email}`}>
                    {frontmatter.email}
                  </Link>
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Typography>{`${frontmatter.street}`}</Typography>
                <Typography>{`${frontmatter.postcode} ${frontmatter.city}`}</Typography>
              </Grid>
            </FooterSection>
            <FooterSection
              item={true}
              xs={10}
              sm={3}
              container={true}
              spacing={2}
            >
              <Grid item={true}>
                <Typography variant='h4'>Pagina&rsquo;s</Typography>
                {frontmatter.links?.map((link, index) => (
                  <Typography>
                    <Link href={link?.url ?? '#'} key={index}>
                      {link?.title}
                    </Link>
                  </Typography>
                ))}
              </Grid>
            </FooterSection>
            <FooterSection
              item={true}
              container={true}
              xs={10}
              sm={3}
              spacing={2}
            >
              <Grid item={true}>
                <Grid>
                  <Typography variant='h4'>Social</Typography>
                </Grid>
                <Grid>
                  <RoundButton
                    color='primary'
                    href='https://www.instagram.com/studiostrack/'
                  >
                    <SvgIcon component={Instagram} />
                  </RoundButton>
                </Grid>
              </Grid>
            </FooterSection>
            <FooterSection
              item={true}
              container={true}
              xs={10}
              sm={3}
              spacing={2}
            >
              <Grid item={true}>
                {/* reviews are not available yet */}
                {/* <Typography variant='h4'>Reviews</Typography> */}
              </Grid>
            </FooterSection>
          </Grid>
        </MaxWidthContainerPadding>
      </FullWidthContainer>
      <BottomLine container={true} justify='center'>
        <MaxWidthContainer
          item={true}
          xs={10}
          sm={12}
          container={true}
          alignItems='center'
        >
          <Typography style={{ fontWeight: 'bold' }}>
            Design: Li moon
          </Typography>
        </MaxWidthContainer>
      </BottomLine>
    </>
  );
};

const FullWidthContainer = withStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
  },
}))(Grid);

const MaxWidthContainerPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(8)}px ${theme.spacing(0)}px`,
  },
}))(MaxWidthContainer);

const BottomLine = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.main,
    height: theme.spacing(8),
    width: '100%',
  },
}))(Grid);

const FooterSection = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))(Grid);
