import { Button, PropTypes } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

export const RoundButton: React.FC<{
  color?: PropTypes.Color;
  type?: 'button' | 'reset' | 'submit' | undefined;
  href?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = ({ color, type, children, ...props }) => {
  const StyledButton = withStyles(() => ({
    root: {
      width: '50px',
      height: '50px',
      minWidth: '50px',
      minHeight: '50px',
      padding: '0px',
      borderRadius: '50%',
      filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))',
    },
  }))(Button);
  return (
    <StyledButton
      variant='contained'
      color={color || 'secondary'}
      type={type}
      {...props}
      // href={href}
      // style={style}
    >
      {children}
    </StyledButton>
  );
};
